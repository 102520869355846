import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import 'src/Pages/StudentPages/login/login.scss'
import loginImages from 'src/assets/images/images.png'
import imageslock from 'src/assets/images/journey.png'
import journey from 'src/assets/images/readingbook.png'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import { login } from 'src/Pages/StudentPages/ApiServices/LoginService'

function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const [successMessage, setSuccessMessage] = useState(location.state?.successMessage)

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage, {
        autoClose: 5000,
        style: { background: 'lightgreen', color: 'white' },
      })
      setSuccessMessage(null)
      navigate(location.pathname, { replace: true, state: {} })
    }
  }, [successMessage, navigate, location.pathname])

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!email.trim()) {
      setEmailError('Email is required')
      return false
    } else if (!emailRegex.test(email.trim())) {
      setEmailError('Invalid email format')
      return false
    } else {
      setEmailError('')
      return true
    }
  }

  const validatePassword = () => {
    if (!password.trim()) {
      setPasswordError('Password is required')
      return false
    } else {
      setPasswordError('')
      return true
    }
  }

  const isValid = () => {
    const validEmail = validateEmail()
    const validPassword = validatePassword()
    return validEmail && validPassword
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    if (e.target.value.trim()) {
      validateEmail()
    } else {
      setEmailError('')
    }
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
    if (e.target.value.trim()) {
      validatePassword()
    } else {
      setPasswordError('')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!isValid()) return

    try {
      setIsLoading(true)

      const data = await login(email, password) // Login request

      // Store user data in localStorage
      localStorage.setItem('userId', data.userId)
      localStorage.setItem('token', data.token)
      localStorage.setItem('role', data.roles)

      // Check if the user has the 'Counselor' role
      if (data.roles.includes('Counselor')) {
        navigate('/counselordashboard') // Navigate to counselor dashboard
        window.location.reload()
      } else {
        // If the user does not have the 'Counselor' role, show error
        toast.error('Access Denied: Please log in using the appropriate portal for your role', {
          autoClose: 5000,
          style: { background: 'lightcoral', color: 'white', fontSize: 'medium' },
        })
      }
    } catch (error) {
      console.error('Login failed:', error.message)
      // If login fails, show error message
      toast.error('Invalid credentials', {
        autoClose: 5000,
        style: { background: 'lightcoral', color: 'white' },
      })
    } finally {
      setIsLoading(false) // Reset loading state
    }
  }

  return (
    <div className="login-container">
      <CContainer fluid className="h-100 d-flex align-items-center justify-content-center">
        <CRow className="justify-content-center w-100 h-100">
          <CCol md={12}>
            <CCardGroup className="h-100">
              <CCard className="text-white bg-primary py-5 d-md-down-none w-100">
                <CCardBody className="text-center d-flex flex-column justify-content-center align-items-center">
                  <div>
                    <img src={journey} alt="" className="pictablet mb-4" />
                    <p>
                      1Journey <br />
                      <b>Stay with us</b>
                    </p>
                    {/* <Link to="/Register">
                      <CButton color="light" className="buttonHover">
                        Register as a Counselor!
                      </CButton>
                    </Link> */}
                  </div>
                </CCardBody>
              </CCard>
              <CCard className="w-100">
                <CCardBody className="d-flex flex-column justify-content-center">
                  <ToastContainer />
                  <CForm>
                    <img
                      src={imageslock}
                      alt=""
                      className="pic d-flex justify-content-between mb-4"
                    />
                    <p className="text-medium-emphasis text-size text-center">
                      <img src={loginImages} alt="" className="picd mb-2" />
                      <b>Log In as a Counselor</b> to your account
                    </p>
                    <label style={{ fontSize: 'medium' }}>
                      Email
                      <span className="stariccolor">*</span>
                    </label>
                    <CInputGroup className="mb-4">
                      <CFormInput
                        placeholder="Enter your email"
                        value={email}
                        onChange={handleEmailChange}
                      />
                    </CInputGroup>
                    {emailError && (
                      <div style={{ color: 'red', fontSize: 'medium', marginTop: '-20px' }}>
                        {emailError}
                      </div>
                    )}

                    <label style={{ fontSize: 'medium' }}>
                      Password
                      <span className="stariccolor">*</span>
                    </label>
                    <CInputGroup className="mb-4">
                      <CFormInput
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        autoComplete="current-password"
                        value={password}
                        onChange={handlePasswordChange}
                      />
                      <CInputGroupText
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <i
                          className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}
                          aria-hidden="true"
                        ></i>
                      </CInputGroupText>
                    </CInputGroup>
                    {passwordError && (
                      <div style={{ color: 'red', fontSize: 'medium', marginTop: '-20px' }}>
                        {passwordError}
                      </div>
                    )}

                    <CRow className="mb-3">
                      <CCol xs={12} className="d-flex justify-content-end">
                        <Link to="/forgotpassword" className="underline">
                          Forgot password?
                        </Link>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol xs={12}>
                        <CButton
                          color="primary"
                          className="w-100"
                          type="submit"
                          onClick={handleSubmit}
                          disabled={isLoading}
                        >
                          {isLoading ? 'Loading...' : 'Login Account'}
                          {isLoading && (
                            <span
                              className="spinner-border spinner-border-sm ml-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
