import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  CircularProgress,
  Box,
  Grid,
  Paper,
} from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import useRegisterService from '../ApiServices/RegisterService'
import Termandcondition from '../../../assets/termandcondition/termandcondition.pdf'
import { Tooltip } from 'antd'

const Register = () => {
  const { formData, formDataError, isLoading, handleSubmit, handleChange } = useRegisterService()
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = (e) => setIsChecked(e.target.checked)

  const handlePdfPreview = (pdfUrl) => {
    window.open(pdfUrl, '_blank')
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#1e90ffa6',
        // background: 'linear-gradient(to right bottom, rgb(6 88 92 / 38%) 0%, rgb(91 51 255 / 50%) 100%)',
        // background: 'linear-gradient(to right bottom, rgb(6, 88, 92) 0%, rgb(91, 51, 255) 100%)',
        color: 'white',
        p: 1.5,
      }}
    >
      <Paper
        style={{ background: 'rgb(255 255 255 / 81%)' }}
        elevation={3}
        sx={{ maxWidth: 600, width: '100%', p: 3, borderRadius: 1.5 }}
      >
        <Typography
          style={{ color: '#1e90ffa6' }}
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          gutterBottom
        >
          Sign Up
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" textAlign="center" gutterBottom>
          <strong>Create your account to get started</strong>
        </Typography>

        <ToastContainer />

        <form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            {/* Student Information Section */}
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="#1e90ffa6" gutterBottom>
                Information of Students
              </Typography>
              <Box sx={{ border: '1px solid cadetblue', borderRadius: 1.5, p: 1.5 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Student First Name"
                      name="studentFirstName"
                      value={formData.studentFirstName}
                      onChange={handleChange}
                      error={!!formDataError.studentFirstNameError}
                      helperText={formDataError.studentFirstNameError}
                      fullWidth
                      required
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Student Last Name"
                      name="studentLastName"
                      value={formData.studentLastName}
                      onChange={handleChange}
                      error={!!formDataError.studentLastNameError}
                      helperText={formDataError.studentLastNameError}
                      fullWidth
                      required
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Student Email"
                      name="studentEmail"
                      type="email"
                      value={formData.studentEmail}
                      onChange={handleChange}
                      error={!!formDataError.studentEmailError}
                      helperText={formDataError.studentEmailError}
                      fullWidth
                      size="small"
                      required
                    />
                  </Grid>
                  {/* Password Section */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Password"
                      name="password"
                      type="password"
                      value={formData.password}
                      onChange={handleChange}
                      error={!!formDataError.passwordError}
                      helperText={formDataError.passwordError}
                      fullWidth
                      required
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Confirm Password"
                      name="confirmPassword"
                      type="password"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      error={!!formDataError.confirmPasswordError}
                      helperText={formDataError.confirmPasswordError}
                      fullWidth
                      size="small"
                      required
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            {/* Parent Information Section */}
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="#1e90ffa6" gutterBottom>
                Information of Parents/Guardians
              </Typography>
              <Box sx={{ border: '1px solid cadetblue', borderRadius: 2, p: 2 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Parent/Guardian First Name"
                      name="parentFirstName"
                      value={formData.parentFirstName}
                      onChange={handleChange}
                      error={!!formDataError.parentFirstNameError}
                      helperText={formDataError.parentFirstNameError}
                      fullWidth
                      size="small"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Parent/Guardian Last Name"
                      name="parentLastName"
                      value={formData.parentLastName}
                      onChange={handleChange}
                      error={!!formDataError.parentLastNameError}
                      helperText={formDataError.parentLastNameError}
                      fullWidth
                      required
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Parent Email"
                      name="parentEmail"
                      type="email"
                      value={formData.parentEmail}
                      onChange={handleChange}
                      error={!!formDataError.parentEmailError}
                      helperText={formDataError.parentEmailError}
                      fullWidth
                      size="small"
                      required
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            {/* Terms and Conditions */}
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
                label={
                  <Typography variant="body2">
                    I accept the{' '}
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault()
                        handlePdfPreview(Termandcondition)
                      }}
                      style={{ textDecoration: 'none', color: '#1e90ffa6' }}
                    >
                      Terms and Conditions
                    </Link>
                  </Typography>
                }
              />
            </Grid>

            {/* Buttons */}
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Link to="/login" style={{ textDecoration: 'none', color: '#1e90ffa6' }}>
                  Already have an account? Log In
                </Link>
                <Tooltip title={!isChecked ? 'Accept terms and conditions' : ''} arrow>
                  <span>
                    {' '}
                    {/* Wrap Button in a span to enable Tooltip on a disabled button */}
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!isChecked || isLoading}
                      startIcon={isLoading && <CircularProgress size={20} />}
                    >
                      {isLoading ? 'Creating Account...' : 'Create Account'}
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  )
}

export default Register

// import React, { useState } from 'react'
// import { Link } from 'react-router-dom'
// import './register.scss'
// import loginImages from 'src/assets/images/images.png'
// import 'react-international-phone/style.css'
// import { CFormInput } from '@coreui/react'
// import { ToastContainer, toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'
// import useRegisterService from '../ApiServices/RegisterService'
// import Termandcondition from '../../../assets/termandcondition/termandcondition.pdf'
// import { Input } from 'antd'

// const Register = () => {
//   const { formData, formDataError, isLoading, handleSubmit, handleChange } = useRegisterService()
//   // const options = useMemo(() => countryList().getData(), [])
//   const [isChecked, setIsChecked] = useState(false)

//   const handleCheckboxChange = (event) => {
//     setIsChecked(event.target.checked)
//   }

//   const onSubmit = async (e) => {
//     e.preventDefault()
//     try {
//       const success = await handleSubmit()
//       if (success) {
//         toast.success('Registration Successful! Confirmation email sent to you and your parent', {
//           autoClose: 5000,
//           style: { background: 'lightgreen', color: 'white' },
//         })
//       }
//     } catch (error) {
//       toast.error(error.message || 'Registration Error', {
//         autoClose: 5000,
//         style: { background: 'lightcoral', color: 'white' },
//       })
//     }
//   }

//   const handlePdfPreview = async (fileUrl) => {
//     try {
//       const extension = fileUrl.split('.').pop().toLowerCase()

//       if (extension === 'pdf') {
//         window.open(fileUrl, '_blank')
//       } else {
//         window.open(fileUrl, '_blank')
//       }
//     } catch (error) {
//       console.error('Error handling file:', error)
//     }
//   }

//   return (
//     <div
//       className="container-fluid"
//       style={{
//         background: 'linear-gradient(to right bottom, rgb(6 88 92) 0%, rgb(91 51 255) 100%)',
//         color: 'white',
//         minHeight: '100vh', // Ensure it covers the full viewport height
//         position: 'absolute', // Position it absolutely to cover the full screen
//         top: 0,
//         left: 0,
//         width: '100%',
//       }}
//     >
//       <div className="container">
//         <form className="p-4" style={{ border: 'none' }} onSubmit={onSubmit}>
//           <div className="d-flex justify-content-center">
//             {/* <img style={{ width: '20%' }} src={signupimg} alt="" /> */}
//             <h3 style={{ fontSize: '2.75rem', fontFamily: 'system-ui', fontWeight: '700' }}>
//               SignUp
//             </h3>
//           </div>
//           <p className="text-medium-emphasis text-size">
//             <img src={loginImages} alt="" className="picd" />
//             <span style={{ color: 'white' }}>
//               <b>Create</b> your account
//             </span>
//           </p>
//           <div className="container-fluid">
//             <ToastContainer />
//             <div className="row">
//               <div className="col-md-4">
//                 <label style={{ fontSize: 'small' }}>
//                   Student First Name <span style={{ color: 'red' }}>*</span>
//                 </label>

//                 <CFormInput
//                   type="text"
//                   name="studentFirstName"
//                   value={formData.studentFirstName}
//                   onChange={handleChange}
//                   className={`form-control mb-4 ${
//                     formDataError.studentFirstNameError ? 'is-invalid' : ''
//                   }`}
//                   placeholder="Student First Name"
//                 />
//                 {formDataError.studentFirstNameError && (
//                   <div className="invalid-feedback invalid-message">
//                     {formDataError.studentFirstNameError}
//                   </div>
//                 )}
//               </div>
//               <div className="col-md-4">
//                 <label style={{ fontSize: 'small' }}>
//                   Student Last Name <span style={{ color: 'red' }}>*</span>
//                 </label>

//                 <CFormInput
//                   placeholder="Enter student last name"
//                   name="studentLastName"
//                   value={formData.studentLastName}
//                   onChange={handleChange}
//                   className={`form-control mb-4 ${
//                     formDataError.studentLastNameError ? 'is-invalid' : ''
//                   }`}
//                 />
//                 {formDataError.studentLastNameError && (
//                   <div className="invalid-feedback invalid-message">
//                     {formDataError.studentLastNameError}
//                   </div>
//                 )}
//               </div>
//               <div className="col-md-4">
//                 <label style={{ fontSize: 'small' }}>
//                   Student Email <span style={{ color: 'red' }}>*</span>
//                 </label>

//                 <CFormInput
//                   name="studentEmail"
//                   placeholder="Enter your email"
//                   value={formData.studentEmail}
//                   className={`form-control mb-4 ${
//                     formDataError.studentEmailError ? 'is-invalid' : ''
//                   }`}
//                   onChange={handleChange}
//                 />
//                 {formDataError.studentEmailError && (
//                   <div className="invalid-feedback invalid-message">
//                     {formDataError.studentEmailError}
//                   </div>
//                 )}
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-md-4">
//                 {' '}
//                 <label style={{ fontSize: 'small' }}>
//                   Parent/Guardian first name <span style={{ color: 'red' }}>*</span>
//                 </label>
//                 <CFormInput
//                   placeholder="Enter parent's first name"
//                   name="parentFirstName"
//                   value={formData.parentFirstName}
//                   onChange={handleChange}
//                   className={`form-control mb-4 ${
//                     formDataError.parentFirstNameError ? 'is-invalid' : ''
//                   }`}
//                 />
//                 {formDataError.parentFirstNameError && (
//                   <div className="invalid-feedback invalid-message">
//                     {formDataError.parentFirstNameError}
//                   </div>
//                 )}
//               </div>
//               <div className="col-md-4">
//                 <label style={{ fontSize: 'small' }}>
//                   Parent/Guardian last name <span style={{ color: 'red' }}>*</span>
//                 </label>

//                 <CFormInput
//                   placeholder="Enter parent's last name"
//                   name="parentLastName"
//                   value={formData.parentLastName}
//                   onChange={handleChange}
//                   className={`form-control mb-4 ${
//                     formDataError.parentLastNameError ? 'is-invalid' : ''
//                   }`}
//                 />
//                 {formDataError.parentLastNameError && (
//                   <div className="invalid-feedback invalid-message">
//                     {formDataError.parentLastNameError}
//                   </div>
//                 )}
//               </div>
//               <div className="col-md-4">
//                 <label style={{ fontSize: 'small' }}>
//                   Parent Email <span style={{ color: 'red' }}>*</span>
//                 </label>

//                 <CFormInput
//                   placeholder="Enter parent's email"
//                   name="parentEmail"
//                   value={formData.parentEmail}
//                   onChange={handleChange}
//                   className={`form-control mb-4 ${
//                     formDataError.parentEmailError ? 'is-invalid' : ''
//                   }`}
//                 />
//                 {formDataError.parentEmailError && (
//                   <div className="invalid-feedback invalid-message">
//                     {formDataError.parentEmailError}
//                   </div>
//                 )}
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-md-4">
//                 {' '}
//                 <label style={{ fontSize: 'small' }}>
//                   Password <span style={{ color: 'red' }}>*</span>
//                 </label>
//                 <Input.Password
//                   name="password"
//                   value={formData.password}
//                   onChange={handleChange}
//                   className={`mb-4 ${formDataError.passwordError ? 'is-invalid' : ''}`}
//                   placeholder="Enter your password"
//                 />
//                 {formDataError.passwordError && (
//                   <div className="invalid-feedback invalid-message">
//                     {formDataError.passwordError}
//                   </div>
//                 )}
//               </div>
//               <div className="col-md-4">
//                 <label style={{ fontSize: 'small' }}>
//                   Confirm Password <span style={{ color: 'red' }}>*</span>
//                 </label>

//                 <Input.Password
//                   name="confirmPassword"
//                   value={formData.confirmPassword}
//                   onChange={handleChange}
//                   className={`mb-4 ${formDataError.confirmPasswordError ? 'is-invalid' : ''}`}
//                   placeholder="Confirm password"
//                 />
//                 {formDataError.confirmPasswordError && (
//                   <div className="invalid-feedback invalid-message">
//                     {formDataError.confirmPasswordError}
//                   </div>
//                 )}
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-md-6">
//                 <label>
//                   <input
//                     type="checkbox"
//                     className="custom-checkbox"
//                     checked={isChecked}
//                     onChange={handleCheckboxChange}
//                   />
//                   Accept &nbsp;
//                   <a
//                     href="#"
//                     onClick={(e) => {
//                       e.preventDefault() // Prevent default link behavior
//                       handlePdfPreview(Termandcondition) // Open PDF in a new tab
//                     }}
//                     style={{ textDecoration: 'none', color: 'orange' }}
//                   >
//                     Terms and Conditions
//                   </a>
//                 </label>
//               </div>
//               <div className="col-md-6 d-flex justify-content-end">
//                 <Link to="/login" color="link" className="underline btncolor px-0">
//                   Already have an account?
//                 </Link>
//               </div>
//             </div>
//             <br></br>
//             <div className="col-md-12 d-flex justify-content-end" style={{ marginTop: '-20px' }}>
//               <button
//                 className="btn"
//                 style={{ backgroundColor: 'blueviolet', color: 'white' }}
//                 onClick={handleSubmit}
//                 disabled={!isChecked}
//               >
//                 {isLoading ? 'Please Wait...' : 'Create Account'}
//                 {isLoading && (
//                   <span
//                     className="spinner-border spinner-border-sm"
//                     role="status"
//                     aria-hidden="true"
//                   ></span>
//                 )}
//               </button>
//             </div>
//           </div>
//         </form>
//       </div>
//     </div>
//   )
// }

// export default Register
