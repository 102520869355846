import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Button, message, Typography, Space, Card } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined } from '@ant-design/icons'

const { Title, Text } = Typography

const SetPassword = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [token, setToken] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    // Extract token from URL
    const searchParams = new URLSearchParams(window.location.search)
    const tokenFromUrl = searchParams.get('token')
    setToken(tokenFromUrl)
  }, [])

  const handlePasswordSet = async (values) => {
    const { password, confirmPassword } = values

    // Validate that passwords match
    if (password !== confirmPassword) {
      message.error('Passwords do not match')
      return
    }

    setIsLoading(true)

    try {
      const response = await axios.post(
        'https://soft.recipe4foodies.com/api/Account/Set-Password',
        {
          token,
          password,
        },
      )
      message.success('Password set successfully')
      localStorage.clear()
      navigate('/login', { state: { successMessage: 'Password set successfully. Please log in.' } })
    } catch (error) {
      message.error(error.response.data)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Card
      style={{
        width: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '7%',
        padding: '24px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        backgroundColor: '#ffffff',
      }}
    >
      <Title level={3} style={{ textAlign: 'center' }}>
        Set Your Password
      </Title>
      <Text type="secondary" style={{ display: 'block', textAlign: 'center', marginBottom: 24 }}>
        Please enter your new password to complete the process.
      </Text>

      <Form
        name="setPassword"
        layout="vertical"
        onFinish={handlePasswordSet}
        requiredMark={false}
        autoComplete="off"
      >
        <Form.Item
          name="password"
          label="New Password"
          rules={[
            { required: true, message: 'Please enter your new password' },
            { min: 8, message: 'Password must be at least 8 characters long' },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Enter new password"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label="Confirm New Password"
          dependencies={['password']}
          rules={[
            { required: true, message: 'Please confirm your new password' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Passwords do not match'))
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Confirm new password"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>

        <Form.Item>
          <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="primary" htmlType="submit" loading={isLoading} style={{ width: '150px' }}>
              {isLoading ? 'Please Wait...' : 'Set Password'}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default SetPassword

// import React, { useState, useEffect } from 'react'
// import axios from 'axios'
// import { useNavigate } from 'react-router-dom'
// import { CFormInput, CInputGroup, CInputGroupText, CButton } from '@coreui/react'
// import { ToastContainer, toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'

// function SetPassword() {
//   const [password, setPassword] = useState('')
//   const [confirmPassword, setConfirmPassword] = useState('')
//   const [passwordError, setPasswordError] = useState('')
//   const [confirmPasswordError, setConfirmPasswordError] = useState('')
//   const [token, setToken] = useState('')
//   const [showPassword, setShowPassword] = useState(false)
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false)
//   const [loading, setLoading] = useState(false)
//   const navigate = useNavigate()

//   useEffect(() => {
//     const searchParams = new URLSearchParams(window.location.search)
//     const tokenFromUrl = searchParams.get('token')
//     setToken(tokenFromUrl)
//     console.log('token From Url', tokenFromUrl)
//   }, [])

//   const validatePassword = () => {
//     if (!password) {
//       setPasswordError('Password is required')
//       return false
//     }
//     return true
//   }

//   const validateConfirmPassword = () => {
//     if (!confirmPassword) {
//       setConfirmPasswordError('Confirm Password is required')
//       return false
//     } else if (confirmPassword !== password) {
//       setConfirmPasswordError('Passwords do not match')
//       return false
//     }
//     return true
//   }

//   const handleSetPassword = async (e) => {
//     e.preventDefault()

//     const isPasswordValid = validatePassword()
//     const isConfirmPasswordValid = validateConfirmPassword()

//     if (isPasswordValid && isConfirmPasswordValid) {
//       try {
//         setLoading(true)
//         const response = await axios.post(
//           'https://soft.recipe4foodies.com/api/Account/Set-Password',
//           {
//             password: password,
//             token: token,
//           },
//         )

//         if (response.status === 200) {
//           navigate('/login', {
//             state: {
//               successMessage: 'Password Set Successfully Please login',
//             },
//           })
//         } else {
//           toast.error('Failed to set Password', {
//             autoClose: 5000,
//             style: { background: 'lightcoral', color: 'white' },
//           })
//         }
//       } catch (error) {
//         console.error('OOPS:', error.message)
//         toast.error(error.response.data)
//       } finally {
//         setLoading(false)
//       }
//     }
//   }

//   return (
//     <>
//       <ToastContainer />
//       <div
//         style={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           height: '100vh',
//           backgroundColor: '#f8f9fa',
//         }}
//       >
//         <div
//           className="card shadow-sm"
//           style={{ width: '24rem', padding: '20px', borderRadius: '8px' }}
//         >
//           <h3 className="text-center mb-4" style={{ color: '#007bff' }}>
//             Set Your Password
//           </h3>
//           <form onSubmit={handleSetPassword}>
//             <div className="mb-3">
//               <label className="form-label" style={{ fontWeight: 'bold' }}>
//                 New Password
//               </label>
//               <CInputGroup>
//                 <CFormInput
//                   type={showPassword ? 'text' : 'password'}
//                   placeholder="Enter your new password"
//                   value={password}
//                   onChange={(e) => {
//                     setPassword(e.target.value)
//                     setPasswordError('')
//                   }}
//                 />
//                 <CInputGroupText
//                   style={{ cursor: 'pointer' }}
//                   onClick={() => setShowPassword(!showPassword)}
//                 >
//                   <i className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}></i>
//                 </CInputGroupText>
//               </CInputGroup>
//               {passwordError && <div className="text-danger mt-1">{passwordError}</div>}
//             </div>

//             <div className="mb-3">
//               <label className="form-label" style={{ fontWeight: 'bold' }}>
//                 Confirm Password
//               </label>
//               <CInputGroup>
//                 <CFormInput
//                   type={showConfirmPassword ? 'text' : 'password'}
//                   placeholder="Re-enter your password"
//                   value={confirmPassword}
//                   onChange={(e) => {
//                     setConfirmPassword(e.target.value)
//                     setConfirmPasswordError('')
//                   }}
//                 />
//                 <CInputGroupText
//                   style={{ cursor: 'pointer' }}
//                   onClick={() => setShowConfirmPassword(!showConfirmPassword)}
//                 >
//                   <i className={showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}></i>
//                 </CInputGroupText>
//               </CInputGroup>
//               {confirmPasswordError && (
//                 <div className="text-danger mt-1">{confirmPasswordError}</div>
//               )}
//             </div>

//             <div className="d-grid mt-4">
//               <CButton color="primary" type="submit" disabled={loading}>
//                 {loading ? (
//                   <>
//                     <span
//                       className="spinner-border spinner-border-sm"
//                       role="status"
//                       aria-hidden="true"
//                     ></span>
//                     {' Loading...'}
//                   </>
//                 ) : (
//                   'Set Password'
//                 )}
//               </CButton>
//             </div>
//           </form>
//         </div>
//       </div>
//     </>
//   )
// }

// export default SetPassword
