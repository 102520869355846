import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Button, message, Typography, Space, Card } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined } from '@ant-design/icons'

const { Title, Text } = Typography

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [token, setToken] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    // Extract token from URL
    const searchParams = new URLSearchParams(window.location.search)
    const tokenFromUrl = searchParams.get('token')
    setToken(tokenFromUrl)
  }, [])

  const handlePasswordReset = async (values) => {
    const { password, confirmPassword } = values

    // Validate that passwords match
    if (password !== confirmPassword) {
      message.error('Passwords do not match')
      return
    }

    setIsLoading(true)

    try {
      const response = await axios.post(
        'https://soft.recipe4foodies.com/api/Account/Forget-Password-Confirm',
        {
          token,
          password,
        },
      )
      message.success('Password reset successfully')
      navigate('/login', { state: { successMessage: 'Password reset successfully' } })
    } catch (error) {
      message.error(error.response.data)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Card
      style={{
        width: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '7%',
        padding: '24px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        backgroundColor: '#ffffff',
      }}
    >
      <Title level={3} style={{ textAlign: 'center' }}>
        Reset Your Password
      </Title>
      <Text type="secondary" style={{ display: 'block', textAlign: 'center', marginBottom: 24 }}>
        Enter your new password to reset it securely.
      </Text>

      <Form
        name="resetPassword"
        layout="vertical"
        onFinish={handlePasswordReset}
        requiredMark={false}
        autoComplete="off"
      >
        <Form.Item
          name="password"
          label="New Password"
          rules={[
            { required: true, message: 'Please enter your new password' },
            { min: 8, message: 'Password must be at least 8 characters long' },
            // {
            //   pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/,
            //   message:
            //     'Password must include uppercase, lowercase, a number, and a special character',
            // },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Enter new password"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label="Confirm New Password"
          dependencies={['password']}
          rules={[
            { required: true, message: 'Please confirm your new password' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Passwords do not match'))
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Confirm new password"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>

        <Form.Item>
          <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="primary" htmlType="submit" loading={isLoading} style={{ width: '150px' }}>
              {isLoading ? 'Please Wait...' : 'Reset Password'}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default ResetPassword
