// apiService.js
import axios from 'axios'

const API_BASE_URL = 'https://soft.recipe4foodies.com/api'

const apiService = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

const login = async (email, password) => {
  try {
    const response = await apiService.post('/Account/Login', { email, password })
    // const response = await apiService.post('/Account/Login-threeMint', { email, password })
    return response.data
  } catch (error) {
    console.log(error)
    throw new Error('Invalid credentials')
  }
}

export { login }
