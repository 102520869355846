import React, { useState } from 'react'
import './forgotpassword.scss'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Input } from 'antd'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!email) {
      toast.error('Email is required', {
        autoClose: 5000,
        style: { background: 'lightcoral', color: 'white' },
      })
      setError('Email is required')
      return
    }

    setIsLoading(true)

    try {
      const response = await axios.post(
        'https://soft.recipe4foodies.com/api/Account/Forget-Password-Email',
        {
          email,
        },
      )

      toast.success('Password reset email sent', {
        autoClose: 5000,
        style: { background: 'lightgreen', color: 'black' },
      })
    } catch (error) {
      toast.error(error.response.data, {
        autoClose: 5000,
        style: { background: 'lightcoral', color: 'white' },
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    setEmail(value)
    if (value.trim()) {
      setError('')
    } else {
      setError('Email is required')
    }
  }

  return (
    <>
      <section className="wrapper">
        <div style={{ marginTop: '7%' }}>
          <div className="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 text-center">
            <div className="logo">
              {/* <img decoding="async" src="images/logo.png" className="img-fluid" alt="logo" /> */}
            </div>
            <form className="rounded bg-white shadow p-5" onSubmit={handleSubmit}>
              <ToastContainer />
              <h3 className="text-dark fw-bolder fs-4 mb-2">Forget Password ?</h3>

              <div className="fw-normal text-muted mb-4">
                Enter your email to reset your password.
              </div>

              <div className="form-floating mb-3">
                <Input
                  type="email"
                  id="floatingInput"
                  style={{
                    height: '40px',
                    borderColor: error ? 'red' : '#d9d9d9', // Dynamic border color
                    boxShadow: error ? '0 0 5px red' : 'none', // Add a subtle red shadow if there's an error
                  }}
                  placeholder="name@example.com"
                  value={email}
                  onChange={handleInputChange}
                />
                {error && (
                  <span style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{error}</span>
                )}
              </div>

              <button
                type="submit"
                className="btn btn-primary submit_btn mb-2"
                disabled={isLoading}
              >
                {isLoading ? 'Submitting...' : 'Submit'}
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default ForgotPassword
