import { message } from 'antd'
import { useState } from 'react'
import 'react-international-phone/style.css'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function useRegisterService() {
  const [formData, setFormData] = useState({
    studentEmail: '',
    studentFirstName: '',
    studentLastName: '',
    parentFirstName: '',
    parentLastName: '',
    parentEmail: '',
    password: '',
    confirmPassword: '',
  })
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const [formDataError, setFormDataError] = useState({
    studentEmailError: '',
    studentFirstNameError: '',
    studentLastNameError: '',
    parentFirstNameError: '',
    parentLastNameError: '',
    parentEmailError: '',
    passwordError: '',
    confirmPasswordError: '',
  })

  const handleChange = (e) => {
    if (!e || !e.target) {
      console.error('Event or target is undefined:', e)
      return
    }

    const { name, value } = e.target
    // Clear the error message for the field being edited
    setFormDataError({ ...formDataError, [`${name}Error`]: '' })
    // setFormDataError({ ...formDataError, studentMobileNumberError: '' })
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setIsLoading(true) // Set loading state to true
      let hasError = false

      // Check for errors in each field except excluded fields
      Object.keys(formData).forEach((fieldName) => {
        if (typeof formData[fieldName] === 'string' && !formData[fieldName].trim()) {
          const fieldNameWithSpaces = fieldName
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase())
          setFormDataError((prevState) => ({
            ...prevState,
            [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
          }))
          hasError = true
        }
      })

      // Check if parent and student emails are the same
      if (
        formData.studentEmail &&
        formData.parentEmail &&
        formData.studentEmail === formData.parentEmail
      ) {
        toast.error('Parent and student emails cannot be the same.', {
          autoClose: 5000,
          style: { background: 'lightcoral', color: 'white' },
        })
        setIsLoading(false)
        return
      }

      if (formData.password.length < 8) {
        setFormDataError((prevState) => ({
          ...prevState,
          passwordError: 'Password must be at least 8 characters long.',
        }))
        hasError = true
      }

      // Check if passwords match
      if (formData.password !== formData.confirmPassword) {
        setFormDataError((prevState) => ({
          ...prevState,
          confirmPasswordError: 'Passwords do not match.',
        }))
        hasError = true
      }

      if (hasError) {
        console.log('Validation errors detected')
        setIsLoading(false)
        return
      }
      // Prevent sending confirmPassword to the backend
      const { confirmPassword, ...dataToSend } = formData
      const response = await fetch('https://soft.recipe4foodies.com/api/Account/Student-Register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      })

      const contentType = response.headers.get('Content-Type')
      let responseData

      if (contentType && contentType.includes('application/json')) {
        responseData = await response.json()
      } else {
        responseData = await response.text() // Handle non-JSON responses
      }

      if (response.ok) {
        navigate('/emailConfirmation', { state: responseData })
      } else {
        toast.error(responseData, {
          autoClose: 5000,
          style: { background: 'lightcoral', color: 'white' },
        })
      }
    } catch (error) {
      console.error('Error:', error)
      message.error(error.response.data)
    } finally {
      setIsLoading(false)
    }
  }

  return { formData, formDataError, isLoading, handleSubmit, handleChange }
}

export default useRegisterService
